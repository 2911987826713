import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';
import { assign, has, isEmpty } from 'lodash';
import { API_PHOTOS } from '../constants';
/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 * deleteMany       => DELETE http://my.api.url/posts/123, DELETE http://my.api.url/posts/456, DELETE http://my.api.url/posts/789
 */

const uploadPhoto = async (apiUrl, params, resource, methode) => {
    let photo = '';
    if (has(params.data, 'photo')) {
        if (!isEmpty(params.data.photo.rawFile)) {
            const formData = new FormData();
            formData.append('photo', params.data.photo.rawFile);
            formData.append('resource', resource);
            await fetch(apiUrl + '/uploads', {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(result => {
                    photo = API_PHOTOS + result;
                });
        }
    }
    if (!has(params.data, 'photo') && methode === 'create') {
        photo = API_PHOTOS + resource + '/default.jpg';
    }
    return photo;
};

const dataProvider = (apiUrl, httpClient) => {
    return {
        getList: function(resource, params) {
            var _a = params.pagination,
                page = _a.page,
                perPage = _a.perPage;
            var _b = params.sort,
                field = _b.field,
                order = _b.order;
            var query = assign({}, fetchUtils.flattenObject(params.filter), {
                _sort: field,
                _order: order,
                _start: (page - 1) * perPage,
                _end: page * perPage,
            });
            var url = apiUrl + '/' + resource + '?' + stringify(query);
            return httpClient(url).then(function(_a) {
                var headers = _a.headers,
                    json = _a.json;
                if (!headers.has('x-total-count')) {
                    throw new Error(
                        'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                    );
                }
                return {
                    data: json,
                    total: parseInt(
                        headers
                            .get('x-total-count')
                            .split('/')
                            .pop(),
                        10
                    ),
                };
            });
        },
        getOne: function(resource, params) {
            return httpClient(apiUrl + '/' + resource + '/' + params.id).then(
                function(_a) {
                    var json = _a.json;
                    return {
                        data: json,
                    };
                }
            );
        },  getOnel: function(resource, params) {
            return httpClient(apiUrl + '/' + resource + '/' + params.id).then(
                function(_a) {
                    var json = _a.json;
                    return {
                        data:[json] ,
                    };
                }
            );
        },
        getMe: function(resource) {
            return httpClient(apiUrl + '/' + resource).then(function(_a) {
                var json = _a.json;
                return {
                    data: json,
                };
            });
        },
        getMany: function(resource, params) {
            var query = {
                id: params.ids,
            };
            var url = apiUrl + '/' + resource + '?' + stringify(query);
            return httpClient(url).then(function(_a) {
                var json = _a.json;
                return { data: json };
            });
        },
        getManyReference: function(resource, params) {
            var _a;
            var _b = params.pagination,
                page = _b.page,
                perPage = _b.perPage;
            var _c = params.sort,
                field = _c.field,
                order = _c.order;
            var query = assign(
                {},
                fetchUtils.flattenObject(params.filter),
                ((_a = {}),
                (_a[params.target] = params.id),
                (_a._sort = field),
                (_a._order = order),
                (_a._start = (page - 1) * perPage),
                (_a._end = page * perPage),
                _a)
            );
            var url = apiUrl + '/' + resource + '?' + stringify(query);
            return httpClient(url).then(function(_a) {
                var headers = _a.headers,
                    json = _a.json;
                if (!headers.has('x-total-count')) {
                    throw new Error(
                        'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                    );
                }
                return {
                    data: json,
                    total: parseInt(
                        headers
                            .get('x-total-count')
                            .split('/')
                            .pop(),
                        10
                    ),
                };
            });
        },
        sender: async (params) => {
            let data = params.data;
            return httpClient(apiUrl + '/sender', {
                method: 'POST',
                body: JSON.stringify(assign({}, data)),
            }).then(function(_a) {
                var json = _a.json;
                return {
                    data: assign({}, json),
                };
            });
        },
        create: async (resource, params) => {
            let data = params.data;
            return httpClient(apiUrl + '/' + resource, {
                method: 'POST',
                body: JSON.stringify(assign({}, data, { photo: API_PHOTOS + resource +'/default.jpg' })),
            }).then(function(_a) {
                var json = _a.json;
                return {
                    data: assign({}, data, { id: json.id }),
                };
            });
        },
        update: async (resource, params) => {
            let objPhoto = await uploadPhoto(
                apiUrl,
                params,
                resource,
                'update'
            );
            let data = params.data;
            return httpClient(apiUrl + '/' + resource + '/' + params.id, {
                method: 'PUT',
                body: JSON.stringify(assign({}, data, { photo: objPhoto })),
            }).then(function(_a) {
                var json = _a.json;
                return { data: json };
            });
        },
        updateMany: function(resource, params) {
            return Promise.all(
                params.ids.map(function(id) {
                    return httpClient(apiUrl + '/' + resource + '/' + id, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    });
                })
            ).then(function(responses) {
                return {
                    data: responses.map(function(_a) {
                        var json = _a.json;
                        return json.id;
                    }),
                };
            });
        },
        delete: function(resource, params) {
            return httpClient(apiUrl + '/' + resource + '/' + params.id, {
                method: 'DELETE',
            }).then(function(_a) {
                var json = _a.json;
                return { data: json };
            });
        },
        deleteMany: function(resource, params) {
            return Promise.all(
                params.ids.map(function(id) {
                    return httpClient(apiUrl + '/' + resource + '/' + id, {
                        method: 'DELETE',
                    });
                })
            ).then(function(responses) {
                return {
                    data: responses.map(function(_a) {
                        var json = _a.json;
                        return json.id;
                    }),
                };
            });
        },
    };
};

export default dataProvider;

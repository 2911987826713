import React from 'react';
import PropTypes from 'prop-types';

const Menu = ({ onMenuClick, dense }) => {
    return (
        <div className="menuEsante" style={{display: 'none', width: 0, height: 0, margin: 0, padding: 0}} >
          Hello
        </div>
    );
};

Menu.propTypes = {
    onMenuClick: PropTypes.func,
};

export default Menu;

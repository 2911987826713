import { fetchUtils } from 'react-admin';
import { API } from '../constants';
import jsonServerProvider from './provider';


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/json, text/plain, */*',
        });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(API, httpClient);

export default dataProvider;

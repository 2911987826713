import { API } from '../constants';

export default {
    login: ({ username, password }) => {
        const request = new Request(`${API}/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.message);
                }
                return response.json();
            })
            .then(({ id, username, token, permissions }) => {
                localStorage.setItem('user_id', id);
                localStorage.setItem('token', token);
                localStorage.setItem('username', username);
                localStorage.setItem('permissions', permissions);
                return Promise.resolve();
            });
    },
    logout: () => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('user_id');
            localStorage.removeItem('username');
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () =>
        localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject({ redirectTo: '/login' }),
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
};

/* eslint-disable no-lone-blocks */
import React from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from './util/asyncComponent';

{
    //TODO merci de retourner ce code a la fin de livraison de produit.
    /**
     * export default [
    <Route exact path="/" component={asyncComponent(() => import('./containers/home'))} />,
    <Route exact path="/login" component={asyncComponent(() => import('./containers/login'))} />,
    <Route exact path="/register" component={asyncComponent(() => import('./containers/register'))} />,
    <Route exact path="/about" component={asyncComponent(() => import('./containers/about'))} />,
    <Route exact path="/contact" component={asyncComponent(() => import('./containers/contact'))} />,
    <Route exact path="/faq" component={asyncComponent(() => import('./containers/faq'))} />,
    <Route path="/app/profile/:id" component={asyncComponent(() => import('./containers/profile'))} />,
    <Route exact path="/app/listing" component={asyncComponent(() => import('./containers/listing'))} />,
    <Route exact path="/app/policy" component={asyncComponent(() => import('./containers/policy'))} />,
    <Route component={asyncComponent(() => import('./containers/errors/404'))} />,
];
     */
}
// TODO ce code est tomporaire pour la demo et développement seulement
export default [
    <Route
        exact
        path="/"
        component={asyncComponent(() => import('./containers/home'))}
    />,
    <Route
        exact
        path="/login"
        component={asyncComponent(() => import('./containers/login'))}
    />,
    <Route
        exact
        path="/register"
        component={asyncComponent(() => import('./containers/register'))}
    />,
    <Route
        exact
        path="/about"
        component={asyncComponent(() => import('./containers/about'))}
    />,
    <Route
        exact
        path="/contact"
        component={asyncComponent(() => import('./containers/contact'))}
    />,
    <Route
        exact
        path="/reglement"
        component={asyncComponent(() => import('./containers/reglement'))}
    />,
    <Route
        exact
        path="/faq"
        component={asyncComponent(() => import('./containers/faq'))}
    />,
    <Route
        path="/app/profile/:id"
        component={asyncComponent(() => import('./containers/profile'))}
    />,
    <Route
        exact
        path="/app/listing"
        component={asyncComponent(() => import('./containers/listing'))}
    />,
    <Route
        exact
        path="/app/policy"
        component={asyncComponent(() => import('./containers/policy'))}
    />,
    <Route
        exact
        path="/lock"
        component={asyncComponent(() => import('./containers/lock'))}
    />,
    <Route
        component={asyncComponent(() => import('./containers/errors/404'))}
    />,
];

import React from 'react';
import { AppBar } from 'react-admin';


const CustomAppBar = props => {
    return (
        <AppBar className="appBarEsante" style={{display: 'none', width: 0, height: 0, margin: 0, padding: 0}} />
    );
};

export default CustomAppBar;

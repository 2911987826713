export const defaultZoom = 10;
export const defaultCenter = { lat: 33.971588, lng: -6.849813 };
export const contactCenter = [ 33.971588, -6.849813];
export const googleKey = 'AIzaSyCT8Ipdn0RUjZOUJaw2J5i7OJg8qS-QttA';
export const contact = "contact@esante.co.ma";
export const societe = "E SANTE SERVICE MAROC";
export const adresse_pro = "Hay inara bd dakhla n 2";
export const tel_domicile = "0522252321";
//dev
// export const API = 'http://localhost:8000/api'; //DEV
// export const API_PHOTOS = 'http://localhost:8000/photos/'; //DEV
// export const URL_ADMIN = "http://localhost:3000/"; //DEV


// ******************************************************************
//prod
export const API = 'https://esante.co.ma/apis/api'; 
export const API_PHOTOS = 'https://esante.co.ma/apis/photos/'; 
export const URL_ADMIN = "https://esante.co.ma/admin"; 

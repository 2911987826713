import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import authProvider from './auth';
import customRoutes from './routes';
import dataProvider from './api';
import frMessages from './i18n/fr';
import enMessages from './i18n/en';
import arMessages from './i18n/ar';
import { Layout } from './layout';
import { ToastContainer } from 'react-toastify';
// BASE CSS
import './assets/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';
import './assets/css/style.css';
import './assets/css/admin.css';
import './assets/css/menu.css';
import './assets/css/vendors.css';
import './assets/css/icon_fonts/css/all_icons_min.css';
import './assets/css/date_picker.css';
import 'react-toastify/dist/ReactToastify.css';
// PLUGINS
import 'font-awesome/css/font-awesome.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// i18n
const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'en') {
        return enMessages;
    }
    if (locale === 'ar') {
        return arMessages;
    }
    return frMessages;
}, 'fr');

ReactDOM.render(
   
    <Admin

        // title="E SANTE SERVICE MAROC :: ONLINE"
        title="Le Portail Officiel de la Santé Numérique Maroc"
        dataProvider={dataProvider}
        customRoutes={customRoutes}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
    >
       {/* <BrowserRouter> */}
        <ToastContainer />
        <Resource name="villes" />
        <Resource name="countries" />
        <Resource name="nationalities" />
        <Resource name="status" />
        <Resource name="secteurs" />
        <Resource name="specialities" />
        {/* </BrowserRouter> */}
    </Admin>
 
    , document.getElementById('root'));

